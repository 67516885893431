import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ location }) => {

  const image = 'https://rfallp.com/images/assets/images/GettyImages-84831616.jpg';

  return (
    <Layout location={location}>
      <div className="page-wrapper-inner">
        <Seo
          title="Services | Bill Pay and Accounting"
          image={image}
          slug={location.pathname}
          description="Our bill pay and accounting services are customized to fit the complex needs of the ultra affluent individual."
        />
        {/* Main */}
        <section className="wrapper style1">
          <div className="container">
            <div className="row gtr-200">
              <div className="col-4 col-12-narrower">
                <div id="sidebar">

                  {/* Sidebar */}

                  <section>
                    <h3>Services</h3>
                    <ul className="links">
                      <li><Link to="/services/">Overview</Link></li>
                      <li><Link to="/services/bill-pay-and-accounting/">Bill Pay and Accounting</Link></li>
                      <li><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></li>
                      <li><Link to="/services/family-administrative-services/">Family Administrative Services</Link></li>
                      <li><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></li>
                      <li><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></li>
                    </ul>
                  </section>

                </div>
              </div>
              <div className="col-8  col-12-narrower imp-narrower">
                <div id="content">

                  {/* Content */}
                  <article>
                    <header>
                      <h2>Bill Pay and Accounting</h2>
                      <p>
                        Our bill pay and accounting services are customized to fit the complex needs of the ultra affluent individual.
                      </p>
                    </header>

                    <span className="image featured">
                      <StaticImage
                        src="../../assets/images/GettyImages-84831616.jpg"
                        alt=""
                        width={725}
                      />
                    </span>

                    <p>
                    You are assigned to a Rosewood Family Advisors accounting team who assists with the processing of all bills and invoices. While we have strict policies and procedures that govern our workflow, we work with you or your representatives to develop an approach appropriate to your unique structures and needs.
                    </p>
                    <p>
                    Our services include, but are not limited to, the following:
                    </p>
                    <ul>
                      <li>Receipt, review and processing of approved disbursements.</li>
                      <li>Cash flow management.</li>
                      <li>Customized accounting services to manage and monitor budgets, construction, capital calls and other financial projects.</li>
                      <li>Coordination of major purchases such as autos, artwork, real estate, yachts and aircraft.</li>
                      <li>Monthly bank reconciliations.</li>
                    </ul>
                    <p>
                    As part of the bill pay process, each invoice is reviewed for accuracy and completeness. We follow up to resolve discrepancies or erroneous charges.
                    </p>
                    <p>
                    We also provide accounting services for you and your complex entity structures. Reports can be tailored to your specific needs. These include:
                    </p>
                    <ul>
                      <li>Cash flow reports.</li>
                      <li>Income statements.</li>
                      <li>Balance sheet.</li>
                      <li>Statement of net worth.</li>
                      <li>Charitable summaries.</li>
                      <li>Budgets.</li>
                      <li>Spending and analysis of capital needs.</li>
                    </ul>

                  </article>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InteriorPage;
